import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { sendEmailVerification } from 'firebase/auth';
import logo from '../../../assets/white_logo.png';
import { auth } from '../../../firebase/firebase';
import './style.css';

export default function VerifyEmail() {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailVerification = async () => {
    setIsLoading(true);

    try {
      // Send the email verification link
      await sendEmailVerification(auth.currentUser);

      setMessage('Verification email sent. Please check your inbox.');
      setTimeout(() => {
        setMessage('');
      }, 2000);
      setIsLoading(false);
    } catch (error) {
      setError('Error sending verification email. Please try again.');
      setTimeout(() => {
        setError('');
      }, 2000);
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <section className="verifyEmail_page">
      <div className="verifyEmail_form">
        <img src={logo} alt="Logo" className="logo" />
        <div className="header">
          <h1 className="title">Verify Email Address</h1>
          <p className="subtitle">
            Please click the button below to send a verification email to your address.
          </p>
        </div>
        <div className="form_wrap">
          <div className="verification_info">
            {message && <p className="success_msg">{message}</p>}
            {error && <p className="error_msg">{error}</p>}
          </div>
          {isLoading ? (
            <button className="verify_btn" type="button" disabled>
              <div className="spinner"></div>
            </button>
          ) : (
            <button className="verify_btn" type="button" onClick={handleEmailVerification}>
              Verify Email
            </button>
          )}
        </div>
      </div>
    </section>
  );
}
