import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import Swal from "sweetalert2";
import "./style.css";

export default function DepositModal({ isOpen, onClose, onDeposit }) {
  const [prinicipalAmount, setPrinicipalAmount] = useState(0);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [accountType, setAccountType] = useState("Select Account");

  const handleDeposit = () => {
    if (accountType === "Select Account") {
      setError("Please select an account type");
      return;
    }

    const MIN_AMOUNT = 10;
    if (prinicipalAmount < MIN_AMOUNT) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `Cannot deposit less than $${MIN_AMOUNT}`,
        showConfirmButton: false,
        timer: 2000,
      });
      setIsLoading(false);
      setPrinicipalAmount(0);
      return;
    }

    onDeposit(prinicipalAmount, accountType); // Pass the accountType too
    Swal.fire({
      icon: "success",
      title: "Request Sent!",
      text: `"Thank you for your deposit request. Your account manager will be in touch shortly to confirm the details before this transaction is executed."`,
      showConfirmButton: false,
      timer: 2500,
    });
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal_overlay">
      <div className="modal">
        <div className="section_header">
          <h2 className="title">Deposit Request</h2>
        </div>
        <p className="modal_text">I would like to deposit ($):</p>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="prinicipalAmount"
          placeholder="$0.00"
          defaultValue={prinicipalAmount} // Use the formData value here
          decimalsLimit={2}
          onValueChange={(value) => {
            const formattedValue = parseFloat(value).toFixed(2);
            setPrinicipalAmount(parseFloat(formattedValue)); // Store as a number
          }}
        />
        <p className="modal_small">
          When clicking request deposit, your request will be submitted for
          review to your account manager. Please check your email for our
          response.
        </p>
        <div className="input_group">
          <label htmlFor="title">Title</label>
          <select
            name="title"
            className="form_select"
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          >
            <option value="Select Account">Select Account</option>
            <option value="Easy Access">Easy Access</option>
            <option value="1 Year Fixed Saver">1 Year Fixed Saver</option>
            <option value="3 Years Fixed Saver">3 Years Fixed Saver</option>
            <option value="5 Years Fixed Saver">5 Years Fixed Saver</option>
          </select>
        </div>
        {message && <p className="success_msg">{message}</p>}
        {error && <p className="error_msg">{error}</p>}
        <div className="buttons_wrap">
          <button
            onClick={() => {
              handleDeposit();
            }}
            className="submit_btn"
          >
            Request Deposit
          </button>
          {isLoading && <div className="spinner"></div>}
          <button onClick={onClose} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
