// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC6Jo9_CTSLl_maaDJCKzXgOuzO_Z4rR6Q",
  authDomain: "equity-west-securities.firebaseapp.com",
  databaseURL: "https://equity-west-securities-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "equity-west-securities",
  storageBucket: "equity-west-securities.appspot.com",
  messagingSenderId: "540106480151",
  appId: "1:540106480151:web:7dccf2b3b9717c4ec15a52",
  measurementId: "G-4WGWNTDK02"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); 
export const storage = getStorage(app);