import React, { useEffect, useState } from "react";
import {
  formatNumber,
  getAuthUser,
  getUserIpos,
} from "../../../firebase/firestore";
import InvestIpoModal from "../Modals/InvestModal";
import SellIpoModal from "../Modals/SellIpoModal";
import "./style.css";

function IposTable() {
  const [showInvestModal, setShowInvestModal] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);
  const [selectedIpos, setSelectedIpos] = useState(null);
  const [iposData, setIposData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleDetails = (iposId) => {
    if (expandedRow === iposId) {
      setExpandedRow(null); // Close the currently open row
    } else {
      setExpandedRow(iposId); // Open the clicked row
    }
  };

  const handleInvest = (ipos) => {
    setSelectedIpos(ipos);
    setShowInvestModal(true);
  };

  const handleSell = (ipos) => {
    setSelectedIpos(ipos);
    setShowSellModal(true);
  }

  useEffect(() => {
    const fetchIpos = async () => {
    setIsLoading(true); // Set loading status to true at the beginning

    const user = getAuthUser();

    if (!user) {
      console.log("No user is currently authenticated.");
      setIsLoading(false); // Set loading status back to false
      return;
    }

    try {
        const ipos = await getUserIpos(user);
        if (ipos) {
          setIposData(ipos);
        }
        
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
      fetchIpos();
  }, []);

  return (
    <div style={{ width: "100%", overflow: "scroll" }}>
      {isLoading ? (
        <div className="stock_spinner"></div>
      ) : (
        <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table className="ipo_table">
          {iposData.length > 0 ? (
            <>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Share Price</th>
                  <th>Number of Shares</th>
                  <th>Purchase Date</th>
                  <th>Total Cost</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {iposData.map((ipos, index) => (
              
                <tbody key={index}>
                  <tr>
                    <td>
                      <div className="button_grid">
                        <img src={ipos.logo} alt="logo" />
                        <p>{ipos.name}</p>
                      </div>
                    </td>
                    <td>$ {formatNumber(ipos.sharePrice)}</td>
                    <td>{ipos.numberOfShares || 0}</td>
                    <td>{ipos.date}</td>
                    <td>$ {formatNumber((ipos.numberOfShares || 0) * (ipos.sharePrice || 0))}</td>
                    <td>
                      <div className="button_grid">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInvest(ipos);
                          }}
                          className="buy_button"
                        >
                          Invest
                        </button>
                        <button
                          onClick={() => toggleDetails(ipos.id)}
                          className="details_button"
                        >
                          Details
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSell(ipos);
                          }}
                          className="sell_button"
                        >
                          Sell
                        </button>
                      </div>
                    </td>
                  </tr>
                  {expandedRow === ipos.id && (
                       <tr>
                         <td colSpan="6">
                           <div className="holding_dropdown">
                             <div className="dropdown_row">
                               <p className="bold_text">IPO Expected Date:</p>
                               <p className="reg_text">{ipos.expectedDate}</p>
                             </div>
                             <div className="dropdown_row">
                               <p className="bold_text">Expected Listing Price:</p>
                               <p className="reg_text">$ {formatNumber(ipos.expListingPrice)}</p>
                             </div>
                             <div className="dropdown_row">
                               <p className="bold_text">Minimum Investment Amount:</p>
                               <p className="reg_text">$ {formatNumber(ipos.minInvestment)}</p>
                             </div>
                             <div className="dropdown_row">
                               <p className="bold_text">Number of Shares:</p>
                               <p className="reg_text">{ipos.numberOfShares}</p>
                             </div>
                             <div className="dropdown_row">
                               <p className="bold_text">Type:</p>
                               <p className="reg_text">{ipos.type}</p>
                             </div>
                           </div>
                         </td>
                       </tr>
                  )}
                </tbody>
                ))}
            </>
          ) : (
            <tbody>
              <tr>
                <td className="no_holding">No current Holdings.</td>
              </tr>
            </tbody>
          )}
        </table>
        </div>
        </div>
        </div>
      )}
      {/* Invest Ipos Modal */}
      <InvestIpoModal
        isOpen={showInvestModal}
        onClose={() => setShowInvestModal(false)}
        ipo={selectedIpos}
      />
      <SellIpoModal
        isOpen={showSellModal}
        onClose={() => setShowSellModal(false)}
        ipo={selectedIpos}
      />
    </div>
  );
}

export default IposTable;
