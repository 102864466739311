import React, { useEffect, useState } from "react";
import TransactionTable from "../TransactionsTable";
import { FaAngleRight } from "react-icons/fa";
import DepositModal from "../Modals/depositModal";
import WithdrawalModal from "../Modals/withdrawModal";
import { addTransaction, getAuthUser, getCurrentDate, getTransactions } from "../../firebase/firestore";
import "./style.css";

export default function Transactions() {
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false); // State for deposit modal
  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] = useState(false); // State for withdrawal modal
  const [transactions, setTransactions] = useState([]); // State for transactions

  const fetchTransactions = async () => {
    
    const user = getAuthUser();

    if (user) {
      const fetchedTransactions = await getTransactions(user);
      if (fetchedTransactions) {
        setTransactions(fetchedTransactions);
      }
    } else {
      console.error("No user is currently authenticated.");
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const handleWithdraw = async (amount, accountType) => {
    const amountAsNumber = parseFloat(amount);

    const newTransaction = {
      type: "Withdrawal",
      date: getCurrentDate(),
      amount: amountAsNumber.toFixed(2),
      status: "Pending",
      accountType: accountType,
    };
    const userId = getAuthUser();
    try {
      await addTransaction(
       userId,
        newTransaction.date,
        newTransaction.amount,
        newTransaction.type,
        newTransaction.status,
        newTransaction.accountType
      );
      setTransactions((prevTransactions) => [
        ...prevTransactions,
        newTransaction,
      ]);
      fetchTransactions();
    } catch (error) {
      console.error("Error adding withdrawal transaction: ", error);
    }
  };

  const handleDeposit = async (amount, accountType) => {
    try {
      const amountAsNumber = parseFloat(amount);
  
      const newTransaction = {
        type: "Deposit",
        date: getCurrentDate(),
        amount: amountAsNumber.toFixed(2),
        status: "Pending",
        accountType: accountType,
      };
      const userId = getAuthUser();
      await addTransaction(
        userId,
        newTransaction.date,
        newTransaction.amount,
        newTransaction.type,
        newTransaction.status,
        newTransaction.accountType
      );
      setTransactions((prevTransactions) => [
        ...prevTransactions,
        newTransaction,
      ]);
      newTransaction.amount = 0;
      newTransaction.accountType = "Select Account";
      fetchTransactions();
    } catch (error) {
      console.error("Error adding deposit transaction: ", error);
    }
  };

  return (
    <div className="transactions_layout">
      <div className="transaction_menu">
        <ul className="transaction_list">
          <li
            className={`transaction_item active`} // Always active
            onClick={() => {}}
          >
            Transactions
            <FaAngleRight />
          </li>
          <li
            className={`transaction_item`}
            onClick={() => setIsDepositModalOpen(true)}
          >
            Deposit
            <FaAngleRight />
          </li>
          <li
            className={`transaction_item`}
            onClick={() => setIsWithdrawalModalOpen(true)}
          >
            Withdrawal
            <FaAngleRight />
          </li>
        </ul>
      </div>

      <div className="content__area">
        {transactions.length === 0 ? (
          <div className="no_transactions">
            <p className="no_transactionsText">
              No transactions found, add some...
            </p>
          </div>
        ) : (
          <TransactionTable transactions={transactions} />
        )}
        <WithdrawalModal
          isOpen={isWithdrawalModalOpen}
          onClose={() => setIsWithdrawalModalOpen(false)}
          onWithdraw={handleWithdraw}
        />

        <DepositModal
          isOpen={isDepositModalOpen}
          onClose={() => setIsDepositModalOpen(false)}
          onDeposit={handleDeposit}
        />
      </div>
    </div>
  );
}
