import React, { useState } from "react";
import { formatNumber, getAuthUser, getCurrentDate, getUserName, investInIPO } from "../../../firebase/firestore";
import "./style.css"; 

export default function InvestIpoModal({ isOpen, onClose, ipo }) {
  const [numberOfShares, setNumberOfShares] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userId = getAuthUser();

  const handleInvestInIpo = async () => {
    if (!numberOfShares || numberOfShares ===  0) {
      setError(
        `Number of shares must be greater than 0`
      );
      setTimeout(() => setError(""), 4000);
      return;
    }

    const userName = await getUserName();

    const investmentData = {
      logo: ipo.logo,
      name: ipo.name,
      expectedDate: ipo.expectedDate,
      sharePrice: ipo.sharePrice,
      expListingPrice: ipo.expListingPrice,
      date: getCurrentDate(),
      minInvestment: ipo.minInvestment,
      numberOfShares: numberOfShares,
      type: 'invest',
      userName: userName,
    };
    setIsLoading(true);
    try {
      await investInIPO(userId, investmentData);
      setMessage("Investment request has been sent.");
      setTimeout(() => {
        setMessage("");
        setNumberOfShares(0);
        onClose();
      }, 2000);
    } catch (error) {
      setError(
        `There was an issue sending your investment request. Try again later.`
      );
      console.error(error.message);
      setTimeout(() => setError(""), 2000);
    }

    setIsLoading(false);
  };

  if (!isOpen) return null;

  const totalCost = numberOfShares * ipo.sharePrice;
  
  const handleNumberOfSharesChange = (e) => {
    const value = Number(e.target.value);
    setNumberOfShares(isNaN(value) ? 0 : value);
  };

  return (
    <div className="invest_ipo_overlay" onClick={(e) => e.stopPropagation()}>
      <div className="invest_ipo_modal">
        <div className="section_header">
          <div className="logo">
            <img src={ipo.logo} alt={`${ipo.name} Logo`} />
          </div>
          <h2 className="title">{ipo.name}</h2>
          <div className="subtitle">{/* <span>{ipo.expIpoDate}</span> */}</div>
        </div>
        <div className="section_body">
          <div className="more_dets">
            <p className="bold_text">IPO Expected Date:</p>
            <p className="reg_text">{ipo.expectedDate}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">IPO Share Price: </p>
            <p className="reg_text">$ {formatNumber(ipo.sharePrice)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Expected Listing Price:</p>
            <p className="reg_text">$ {formatNumber(ipo.expListingPrice)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Minimum Investment Amount:</p>
            <p className="reg_text">$ {formatNumber(ipo.minInvestment)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Number of Shares:</p>
            <p className="reg_text">{numberOfShares}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Total Cost:</p>
            <p className="reg_text">$ {formatNumber(totalCost)}</p>
          </div>
          <div className="input_group">
            <label htmlFor="title">Number of shares:</label>
            <input
                type="number"
                name="numberOfShares"
                placeholder="0"
                id="numberOfShares"
                value={numberOfShares}
                onChange={handleNumberOfSharesChange}
                min="0"
              />
            </div>
        </div>
        {message && <p className="success_msg">{message}</p>}
        {error && <p className="error_msg">{error}</p>}
        <div className="buttons_wrap">
          <button onClick={handleInvestInIpo} className="submit_btn">
            Invest
          </button>
        {isLoading && (
          <div className="spinner" style={{ margin: "0 auto" }}></div>
        )}
          <button onClick={onClose} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
