/*
* @license
* Copyright 2022 Google LLC
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

// const USER_COLLECTION = "users";
const USERS_COLLECTION = "users";
const ADMIN_DASH_COLLECTION = "admin_users";
const USERS_REQUESTS = "userRequests";
const ADMINUSERS_COLLECTION = "adminUsers";

// Turn off phone auth app verification.
// firebase.auth().settings.appVerificationDisabledForTesting = true;

// Authenticated user
export function getAuthUser() {
  const authInstance = getAuth();
  const user = authInstance.currentUser;

  if (!user) {
    return;
  }
  const authUser = user.uid;
  return authUser;
}

export async function getUserName () {
  const userId = getAuthUser();
  const userDocRef = doc(db, "users", userId);
  let userName = "Unknown User"; 

  try {
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userDetails = userDocSnapshot.data();
      userName = userDetails.fullName; 
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error getting document:", error);
  }
  return userName;
}

export function logoutAndRedirectToLogin() {
  const authInstance = getAuth();
  const user = authInstance.currentUser;

  if (!user) {
    console.log("No user is currently authenticated.");
    return;
  }

  // Sign out the user
  signOut(authInstance)
    .then(() => {
      const navigate = useNavigate();
      navigate("/");
    })
    .catch((error) => {
      console.error("Error signing out:", error);
    });
}

// Get Current Date
export function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// Format Number
export function formatNumber(number) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

export async function addUserRequestToFirestore(formData) {
  try {
    const adminDashRef = await addDoc(
      collection(db, ADMIN_DASH_COLLECTION),
      {}
    );

    const userRequestRef = await addDoc(
      collection(db, ADMIN_DASH_COLLECTION, adminDashRef.id, USERS_REQUESTS),
      {
        ...formData,
      }
    );
    return userRequestRef.id;
  } catch (error) {
    console.error("Error adding user request to Firestore:", error);
    return null;
  }
}

// Function to add login/logout notification to Admin Dashboard
export async function addLogNotification(userRef) {
  try {
    // Fetch the user's data to determine the login status
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const isLoggedIn = userData.isLoggedIn; // Assuming 'isLoggedIn' is a boolean field in the user's data

      // Create a notification message based on the login status
      const adminNotification = isLoggedIn
        ? `User '${userData.fullName}' logged in`
        : `User '${userData.fullName}' logged out`;

      // Send the notification to the admin_users collection
      const notificationData = {
        message: adminNotification,
        // date: getCurrentDate(),
        timeStamp: new Date(),
        isLoggedIn: isLoggedIn,
      };

      // Construct the Firestore references for admin dashboard and sub-collection
      const adminDashRef = collection(db, ADMINUSERS_COLLECTION);
      const notificationDashRef = doc(adminDashRef, "notifications");
      const subCollectionName = isLoggedIn
        ? "loginNotifications"
        : "logoutNotifications";
      const notificationsRef = collection(
        notificationDashRef,
        subCollectionName
      );

      await addDoc(notificationsRef, notificationData);
      return notificationData;
    } else {
      console.error("User not found in Firestore.");
      return null;
    }
  } catch (error) {
    console.error("Error adding user login notification to Firestore:", error);
    return null;
  }
}

export async function getUser(uid) {
  const userRef = doc(db, USERS_COLLECTION, uid);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return [{ ...userSnap.data(), id: userSnap.id }];
  } else {
    return [];
  }
}

export function updateUser(uid, userData) {
  const userDoc = doc(db, USERS_COLLECTION, uid);
  return updateDoc(userDoc, userData);
}

export function deleteUser(uid) {
  const userDoc = doc(db, USERS_COLLECTION, uid);
  return deleteDoc(userDoc);
}

// Transactions
const TRANSACTIONS_SUB_COLLECTION = "transactions";

export function addTransaction(uid, date, amount, type, status, accountType) {
  // Add a transaction to the user-specific transactions sub-collection
  return addDoc(
    collection(db, USERS_COLLECTION, uid, TRANSACTIONS_SUB_COLLECTION),
    {
      date,
      amount,
      type,
      status,
      accountType,
    }
  );
}

export async function getTransactions(uid) {
  // Query the user-specific transactions sub-collection
  const transactionsQuery = query(
    collection(db, USERS_COLLECTION, uid, TRANSACTIONS_SUB_COLLECTION),
    orderBy("date")
  );
  const querySnapshot = await getDocs(transactionsQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no transactions are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

// Banking Details
export async function addBankingDetails(uid, data) {
  const bankingDetailsRef = collection(db, "users", uid, "bankingDetails");
  return addDoc(bankingDetailsRef, data);
}

export async function updateBankingDetails(uid, docId, data) {
  return setDoc(doc(db, "users", uid, "bankingDetails", docId), data);
}

export async function getBankingDetails(uid) {
  const firestorePath = `users/${uid}/bankingDetails`;

  const bankingDetailsQuery = query(collection(db, firestorePath));
  const querySnapshot = await getDocs(bankingDetailsQuery);

  if (querySnapshot.empty) {
    return []; // Return an empty array if no banking details are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

//BondsRequest
const BONDS_SUB_COLLECTION = "bonds";
const HOLDINGS_SUB_COLLECTION = "bondsHoldings";
const BONDS_REQUEST_SUB_COLLECTION = "bondsRequest";

export async function getBonds() {
  const bondsQuery = query(
    collection(db, BONDS_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(bondsQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no bonds are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function getBondsHoldings(uid) {
  const bondsQuery = query(
    collection(db, USERS_COLLECTION, uid, HOLDINGS_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(bondsQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no bonds are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function getBondsRequest(uid) {
  const bondsQuery = query(
    collection(db, USERS_COLLECTION, uid, BONDS_REQUEST_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(bondsQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no bonds are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

async function sendRequestAdmin(uid, bondData, typeOfRequest) {
  const userAdminDocRef = doc(db, `${ADMIN_DASH_COLLECTION}/${uid}`);
  // Ensure the document exists before adding to its subcollection
  await setDoc(
    userAdminDocRef,
    { createdAt: getCurrentDate() },
    { merge: true }
  );

  const bondsRequestPath = collection(
    db,
    `${ADMIN_DASH_COLLECTION}/${uid}/${BONDS_REQUEST_SUB_COLLECTION}`
  );
  return addDoc(bondsRequestPath, {
    ...bondData,
    requestStatus: "Pending",
    typeOfRequest: typeOfRequest,
  });
}

export async function buyBonds(uid, bondData) {
  const userRef = doc(db, "users", uid);
  const userSnapshot = await getDoc(userRef);

  if (userSnapshot.exists()) {
    const userData = userSnapshot.data();
    const userFullName = userData.fullName;

    const notificationData = {
      message: `User '${userFullName}' made a request to buy bonds`,
      timeStamp: new Date(),
    };
    await addDoc(
      collection(
        db,
        ADMINUSERS_COLLECTION,
        "notifications",
        "bondNotifications"
      ),
      notificationData
    );
    return sendRequestAdmin(uid, bondData, "buy");
  } else {
    console.error("User not found in Firestore.");
    return null;
  }
}

export async function sellBonds(uid, bondData) {
  const userRef = doc(db, "users", uid);
  const userSnapshot = await getDoc(userRef);

  if (userSnapshot.exists()) {
    const userData = userSnapshot.data();
    const userFullName = userData.fullName;
    const notificationData = {
      message: `User '${userFullName}' made a request to sell bonds`,
      timeStamp: new Date(),
    };
    await addDoc(
      collection(
        db,
        ADMINUSERS_COLLECTION,
        "notifications",
        "bondNotifications"
      ),
      notificationData
    );

    return (
      // sendRequestToUser(uid, bondData, "sell"), // Assume you have a similar function for user
      sendRequestAdmin(uid, bondData, "sell")
    );
  } else {
    console.error("User not found in Firestore.");
    return null;
  }
}

// Function to delete a bond based on its currentValue
export async function deleteBond(uid, bondId) {
  try {
    const userBondsPath = `users/${uid}/bondsHoldings`;
    const bondDocRef = doc(db, userBondsPath, bondId);

    const bondDoc = await getDoc(bondDocRef);
    console.log("Bond doc:", bondDoc);
    if (bondDoc.exists()) {
      const bondData = bondDoc.data();
      console.log("Bond data:", bondData);
      if (bondData.currentValue <= 0) {
        // Delete the bond document as its currentValue is 0 or less
        console.log(bondData.currentValue <= 0);
        await deleteDoc(bondDocRef);
        console.log(`Bond with ID ${bondId} has been deleted.`);
      } else {
        console.log(
          `Bond with ID ${bondId} cannot be deleted as its currentValue is above 0.`
        );
        return;
      }
    } else {
      console.error(`Bond with ID ${bondId} does not exist.`);
    }
  } catch (error) {
    console.error(`Error deleting bond with ID ${bondId}:`, error);
  }
}

//NOTIFICATIONS
const NOTIFICATIONS_SUB_COLLECTION = "notifications";

export async function getNotificationsForUser(userId) {
  try {
    const notificationsRef = collection(
      db,
      USERS_COLLECTION,
      userId,
      NOTIFICATIONS_SUB_COLLECTION
    );
    const notificationsSnapshot = await getDocs(notificationsRef);

    if (!notificationsSnapshot || notificationsSnapshot.empty) {
      return [];
    }

    const notifications = notificationsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return notifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return null;
  }
}

export const deleteNotification = async (uid, notificationId) => {
  try {
    const requestRef = doc(
      db,
      USERS_COLLECTION,
      uid,
      NOTIFICATIONS_SUB_COLLECTION,
      notificationId
    );
    await deleteDoc(requestRef);
  } catch (error) {
    console.error("Error deleting Notification: ", error);
    throw error;
  }
};

//FIXED TERM DEPOSIT
const FIXED_TERM_SUB_COLLECTION = "fixedTermDeposit";
const FIXED_TERMS_SUB_COLLECTION = "fixedTermDeposits";
const TERM_REQUEST_COLLECTION = "termDepositRequest";

export async function getFixedTerm() {
  const fixedTermQuery = query(
    collection(db, FIXED_TERM_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(fixedTermQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no fixedTerm are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function getUserFixedTerm(uid) {
  const fixedTermQuery = query(
    collection(db, USERS_COLLECTION, uid, FIXED_TERMS_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(fixedTermQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no fixedTerm are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function depositFixedTerm(uid, newDeposit) {
  try {
    const userAdminDocRef = doc(db, `${ADMIN_DASH_COLLECTION}/${uid}`);
    await setDoc(
      userAdminDocRef,
      { createdAt: getCurrentDate() },
      { merge: true }
    );

    const fixedTerm_RequestPath = collection(
      db,
      `${ADMIN_DASH_COLLECTION}/${uid}/${TERM_REQUEST_COLLECTION}`
    );

    const userRef = doc(db, "users", uid);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const userFullName = userData.fullName;
      const notificationData = {
        message: `User '${userFullName}' made a fixed term deposit request`,
        timeStamp: new Date(),
      };
      await addDoc(
        collection(
          db,
          ADMINUSERS_COLLECTION,
          "notifications",
          "termsNotifications"
        ),
        notificationData
      );
    } else {
      console.error("User not found in Firestore.");
    }

    return addDoc(fixedTerm_RequestPath, {
      ...newDeposit,
    });
  } catch (error) {
    console.error("Error during deposit request: ", error);
    throw error; // or handle the error as per your needs.
  }
}

export async function withdrawFixedTerm(uid, fixedTermdData) {
  try {
    const userAdminDocRef = doc(db, `${ADMIN_DASH_COLLECTION}/${uid}`);
    await setDoc(
      userAdminDocRef,
      { createdAt: getCurrentDate() },
      { merge: true }
    );

    const fixedTerm_RequestPath = collection(
      db,
      `${ADMIN_DASH_COLLECTION}/${uid}/${TERM_REQUEST_COLLECTION}`
    );

    const userRef = doc(db, "users", uid);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const userFullName = userData.fullName;
      const notificationData = {
        message: `User '${userFullName}' made a fixed term deposit withdrawal request`,
        timeStamp: new Date(),
      };
      await addDoc(
        collection(
          db,
          ADMINUSERS_COLLECTION,
          "notifications",
          "termsNotifications"
        ),
        notificationData
      );
    } else {
      console.error("User not found in Firestore.");
    }

    return addDoc(fixedTerm_RequestPath, {
      ...fixedTermdData,
    });
  } catch (error) {
    console.error("Error during withdrawal request: ", error);
    throw error; // or handle the error as per your needs.
  }
}

// IPOS
const IPOS_COLLECTION = "ipos";

//getIPOS
export const getIpos = async () => {
  const iposQuery = query(
    collection(db, IPOS_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(iposQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no ipos are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
};

//INVEST
export async function investInIPO(userId, investmentData) {
  try {
    // Check if a document with the user ID already exists
    const userDocRef = doc(db, ADMIN_DASH_COLLECTION, userId);
    const docSnap = await getDoc(userDocRef);

    // If it does not exist, create the document with the user ID
    if (!docSnap.exists()) {
      await setDoc(userDocRef, { userId }); // Set initial data as needed
    }

    // creating a reference to the investAmount sub-collection of the user’s document
    const investAmountCollectionRef = collection(
      userDocRef,
      "ipoInvestmentRequests"
    );

    // constructing the investment data object
    const investmentDataObj = {
      ...investmentData,
      status: "Pending", // Setting initial status as Pending
      timestamp: getCurrentDate(), // Adding a timestamp to know when the request was made
    };

    // adding the investment data to the investAmount sub-collection
    const docRef = await addDoc(investAmountCollectionRef, investmentDataObj);

    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const userFullName = userData.fullName;
      const notificationData = {
        message: `User '${userFullName}' made a request to invest IPOs`,
        timeStamp: new Date(),
      };
      await addDoc(
        collection(
          db,
          ADMINUSERS_COLLECTION,
          "notifications",
          "iposNotifications"
        ),
        notificationData
      );
    } else {
      console.error("User not found in Firestore.");
    }

    return docRef.id; // returning the id of the newly created document
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e; // rethrowing the error after logging it
  }
}

//SELL
export async function sellIPO(userId, investmentData) {
  try {
    // Check if a document with the user ID already exists
    const userDocRef = doc(db, ADMIN_DASH_COLLECTION, userId);
    const docSnap = await getDoc(userDocRef);

    // If it does not exist, create the document with the user ID
    if (!docSnap.exists()) {
      await setDoc(userDocRef, { userId }); // Set initial data as needed
    }

    // creating a reference to the investAmount sub-collection of the user’s document
    const investAmountCollectionRef = collection(
      userDocRef,
      "ipoInvestmentRequests"
    );

    // constructing the investment data object
    const investmentDataObj = {
      ...investmentData,
      status: "Pending", // Setting initial status as Pending
      timestamp: getCurrentDate(), // Adding a timestamp to know when the request was made
    };

    // adding the investment data to the investAmount sub-collection
    const docRef = await addDoc(investAmountCollectionRef, investmentDataObj);

    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const userFullName = userData.fullName;
      const notificationData = {
        message: `User '${userFullName}' made a request to sell IPOs`,
        timeStamp: new Date(),
      };
      await addDoc(
        collection(
          db,
          ADMINUSERS_COLLECTION,
          "notifications",
          "iposNotifications"
        ),
        notificationData
      );
    } else {
      console.error("User not found in Firestore.");
    }

    return docRef.id; // returning the id of the newly created document
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e; // rethrowing the error after logging it
  }
}

//Get Users Ipos
export async function getUserIpos(uid) {
  const iposQuery = query(
    collection(db, USERS_COLLECTION, uid, IPOS_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(iposQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no ipos are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

//CASH DEPOSIT
const CASH_DEPOSIT_SUB_COLLECTION = "cashDeposits";
//get Cash Deposit
export async function getCashDeposit(uid) {
  const cashDepositQuery = query(
    collection(db, USERS_COLLECTION, uid, CASH_DEPOSIT_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(cashDepositQuery);

  if (querySnapshot.empty) {
    return null;
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

// Function to fetch the password policy setting from Firestore
export const fetchPasswordPolicySetting = async () => {
  try {
    const docRef = doc(db, ADMINUSERS_COLLECTION, "strongPasswordPolicy");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const isStrong = docSnap.data().isTrue;
      return isStrong;
    } else {
      return true;
    }
  } catch (error) {
    console.error("Error fetching password policy: ", error);
    throw error;
  }
};

//LIVE CHAT
export const displayChatFeature = (setIsChatVisible) => {
  try {
    const docRef = doc(db, ADMINUSERS_COLLECTION, "chatFeature");

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setIsChatVisible(doc.data().isDisplayed);
      } else {
        return;
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

//display tool featute
export const displayToolsFeature = (setIsToolsVisible) => {
  try {
    const docRef = doc(db, "adminUsers", "toolFeature");

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setIsToolsVisible(doc.data().isDisplayed);
      } else {
        return;
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

//display bonds feature
export const displayBondsFeature = (setIsBondsVisible) => {
  try {
    const docRef = doc(db, "adminUsers", "bondsFeature");

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setIsBondsVisible(doc.data().isTrue);
      } else {
        return;
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

//display fixed term feature
export const displayFixedTermFeature = (setIsFixedTermVisible) => {
  try {
    const docRef = doc(db, "adminUsers", "termsFeature");

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setIsFixedTermVisible(doc.data().isTrue);
      } else {
        return;
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

//display ipo feature
export const displayIpoFeature = (setIsIpoVisible) => {
  try {
    const docRef = doc(db, "adminUsers", "iposFeature");

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setIsIpoVisible(doc.data().isTrue);
      } else {
        return;
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

//display ipos tables feature
export const displayIposTable = (setIsIposVisible) => {
  try {
    const docRef = doc(db, "adminUsers", "iposTableFeature");

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setIsIposVisible(doc.data().isTrue);
      } else {
        return;
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

//send chat
export const sendChat = async (userUid, chatMessage, fullName) => {
  try {
    // Create a new chat document reference
    const newChatRef = doc(collection(db, USERS_COLLECTION, userUid, "chats"));
    const chatId = newChatRef.id;
    await setDoc(newChatRef, {
      chat: chatMessage,
      timeStamp: serverTimestamp(),
      read: false,
      user: "client", // or the client's identifier
      id: userUid,
      chatId: chatId,
      userName: fullName,
    });

    // Add user to 'chatRoom' indicating they have an active chat
    const chatRoomRef = doc(
      db,
      ADMINUSERS_COLLECTION,
      "chatRoom",
      "activeChats",
      userUid
    );
    await setDoc(chatRoomRef, { active: true });

    return { chatId, chatMessage, userUid };
  } catch (err) {
    console.error(err);
    throw new Error("Failed to send chat message");
  }
};

//get admin chats
export const fetchChats = (db, userUid, callback) => {
  try {
    const chatsRef = collection(db, "users", userUid, "chats");
    const q = query(chatsRef, orderBy("timeStamp", "asc")); // Ordering chats in ascending order

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chats = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(chats); // Callback to handle the real-time chat data
    });

    return unsubscribe; // Returns the unsubscribe function for cleanup
  } catch (err) {
    console.error(err);
    throw new Error("Failed to fetch chats");
  }
};

// delete chat
export const closeChat = async (db, userUid) => {
  try {
    const chatsRef = collection(db, USERS_COLLECTION, userUid, "chats");
    const querySnapshot = await getDocs(query(chatsRef));

    // Delete each chat document
    for (const doc of querySnapshot.docs) {
      await deleteDoc(doc.ref);
    }

    // Add a notification to the user's notifications subcollection
    await addDoc(collection(db, USERS_COLLECTION, userUid, "notifications"), {
      message: "Your have ended the chat",
      timeStamp: serverTimestamp(),
    });

    // Add a notification to the admin's notifications subcollection
    const notificationData = {
      message: `${userUid} has ended the chat.`,
      timeStamp: new Date(),
    };

    // Construct the Firestore references for admin dashboard and sub-collection
    const adminDashRef = collection(db, ADMINUSERS_COLLECTION);
    const notificationDashRef = doc(adminDashRef, "notifications");
    const subCollectionName = "chatNotifications";
    const notificationsRef = collection(notificationDashRef, subCollectionName);

    await addDoc(notificationsRef, notificationData);

    // Remove user from 'chatRoom'
    const chatRoomRef = doc(
      db,
      ADMINUSERS_COLLECTION,
      "chatRoom",
      "activeChats",
      userUid
    );
    await deleteDoc(chatRoomRef);

    return notificationData;
  } catch (err) {
    console.error("Failed to close the chat:", err);
    throw new Error("Failed to close the chat");
  }
};

//STOCKS
const STOCKS_COLLECTION = "stocks";
// Function to get stock data from the user's database
export async function getStockFromUserDB(userId) {
  try {
    const userDocRef = doc(db, USERS_COLLECTION, userId);

    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      // Creating a reference to the stocks sub-collection of the user's document
      const stocksCollectionRef = collection(userDocRef, STOCKS_COLLECTION);
      const stocksSnapshot = await getDocs(stocksCollectionRef);

      const stocksData = stocksSnapshot.docs.map((doc) => doc.data());
      return stocksData;
    } else {
      console.error(`User with ID ${userId} not found.`);
      return null;
    }
  } catch (error) {
    console.error("Error getting stock from user database: ", error);
    throw error;
  }
}

// Function to update a single stock in Firestore
export const updateStockInDB = async (userId, stock) => {
  try {
    if (!stock.id) {
      throw new Error("Stock ID is missing or invalid");
    }
    const userDocRef = doc(db, USERS_COLLECTION, userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const stocksCollectionRef = collection(userDocRef, STOCKS_COLLECTION);
      const stockRef = doc(stocksCollectionRef, stock.id);
      await updateDoc(stockRef, stock);
    } else {
      console.error(`Error processing Update.`);
      return null;
    }
  } catch (error) {
    console.error("Error updating stock in the database:", error);
  }
};
