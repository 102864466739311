import React, { useState, useEffect } from "react";
import {
  deleteNotification,
  getAuthUser,
  getNotificationsForUser,
} from "../../firebase/firestore";
import { RxCross2 } from "react-icons/rx";
import "./style.css";
import { auth } from "../../firebase/firebase";
import LoadingScreen from "../LoadingScreen";

const NotificationPage = () => {
  // Assuming notifications are fetched from some source like API
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = getAuthUser();

  useEffect(() => {
    const fetchNotifications = async () => {
      
      const userNotifications = await getNotificationsForUser(userId);
      if (userNotifications) {
        // Sorting by timestamp in descending order
        userNotifications.sort((a, b) => b.timestamp - a.timestamp);
        setNotifications(userNotifications);
      }
    };
    fetchNotifications();
  }, []);

  const handleDeleteNotification = async (notificationId) => {
    try {
      setLoading(true);
      console.log(notificationId, userId)
      // Assuming notification is deleted from some source like API
      await deleteNotification(userId, notificationId);

      // Update the notifications list by removing the deleted notification
      const updatedNotifications = notifications.filter(
        (notification) => notification.id !== notificationId
      );

      setNotifications(updatedNotifications);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="notification_page">
      <div className="section_header">
        <h2 className="title">Notifications</h2>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>
      {
        loading && <LoadingScreen />
      }
      <div className="notifications_container">
        {notifications.map((notification, index) => (
          <div
            key={index} // Use the index as the key
            className={`notification ${
              notification.seen ? notification.type : "unseen"
            }`}
          >
            <button
              className="cancel_button"
              onClick={() => handleDeleteNotification(notification.id)} // Pass the index
            >
              <RxCross2 />
            </button>
            <div className="info">
            <div className="type">
              <p> {notification.type} </p>
            </div>
            <p>{notification.message}</p>
            </div>
          </div>
        ))}

        {notifications.length === 0 && (
          <small>No notification available.</small>
        )}
      </div>
    </div>
  );
};

export default NotificationPage;
