import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import "./style.css";
import { formatNumber } from "../../firebase/firestore";

export default function InterestCalculator() {
  const [amount, setAmount] = useState(5000);
  const [interestRate, setInterestRate] = useState(0.046);
  const [duration, setDuration] = useState(3);
  const [results, setResults] = useState({});

  const handleAmountChange = (value) => {
    setAmount(value);
  };

  const handleAccountOptionChange = (event) => {
    const account = event.target.value;
    switch (account) {
      case "Easy Access":
        setInterestRate(0.031);
        setDuration(1);
        break;
      case "1 Year Fixed Saver":
        setInterestRate(0.042);
        setDuration(1);
        break;
      case "3 Year Fixed Saver":
        setInterestRate(0.046);
        setDuration(3);
        break;
      case "5 Year Fixed Saver":
        setInterestRate(0.051);
        setDuration(5);
        break;
      default:
        setInterestRate(0.046);
        setDuration(3);
    }
  };

  const calculateInterest = () => {
    // Calculate daily return without intermediate rounding
    const dailyReturn = (amount * interestRate) / 365;

    // Calculate monthly return based on average days in a month (30.44)
    const monthlyReturn = dailyReturn * 30.44;

    // Calculate annual return without intermediate rounding
    const annualReturn = dailyReturn * 365;

    // Calculate total maturity return without intermediate rounding
    const totalMaturityReturn = annualReturn * duration;

    // Calculate total without compounding without intermediate rounding
    const total = amount + totalMaturityReturn;

    // Now, round and format the results
    // const formattedResults = {
    //   dailyReturn: dailyReturn.toLocaleString("en-US", {
    //     style: "currency",
    //     currency: "USD",
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }),
    //   monthlyReturn: monthlyReturn.toLocaleString("en-US", {
    //     style: "currency",
    //     currency: "USD",
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }),
    //   annualReturn: annualReturn.toLocaleString("en-US", {
    //     style: "currency",
    //     currency: "USD",
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }),
    //   total: total.toLocaleString("en-US", {
    //     style: "decimal",
    //     currency: "USD",
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }),
    //   totalMaturityReturn: totalMaturityReturn.toLocaleString("en-US", {
    //     style: "currency",
    //     currency: "USD",
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }),
    // };
    const results = {
      dailyReturn: formatNumber(dailyReturn, 2),
      monthlyReturn: formatNumber(monthlyReturn, 2),
      annualReturn: formatNumber(annualReturn, 2),
      total: formatNumber(total, 2),
      totalMaturityReturn: formatNumber(totalMaturityReturn, 2),
    };
    console.log(formatNumber(total));
    setResults(results);
  };
  
  return (
    <section className="interestCalculator_section">
      <div className="interestCalculator_container">
        <div className="wrap_one">
          <h2 className="title">Account Options</h2>
          <div className="wrap_head">
            <div className="interestCalculator_item">
              <p className="interestCalculator_text">Amount to deposit:</p>
              <CurrencyInput
                decimalSeparator="."
                prefix="$"
                className="field"
                name="minimumAmount"
                placeholder="$5,000"
                decimalsLimit={2}
                onValueChange={handleAmountChange}
              />
            </div>
            <div className="interestCalculator_item">
              <button className="calculate_btn" onClick={calculateInterest}>
                Calculate
              </button>
            </div>
          </div>
          <div className="wrap_body">
            <div className="wrapper">
              <label className="container_app ">
                <input
                  value="Easy Access"
                  type="radio"
                  name="bond_options"
                  onChange={handleAccountOptionChange}
                />
                <div className="checkmark_app">
                  <div className="flex_column">
                    <div className="card_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        viewBox="0 0 74 75"
                      >
                        <g
                          id="Rectangle_12"
                          data-name="Rectangle 12"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        >
                          <rect
                            width="74"
                            height="75"
                            rx="15"
                            stroke="none"
                          ></rect>
                          <rect
                            x="1"
                            y="1"
                            width="72"
                            height="73"
                            rx="14"
                            fill="none"
                          ></rect>
                        </g>
                        <g
                          id="Group_16"
                          data-name="Group 16"
                          transform="translate(1997.478 1142.783)"
                        >
                          <path
                            id="Path_7"
                            data-name="Path 7"
                            d="M-1980.519-1092.268c-.854,0-1.621.006-2.388,0-.6-.006-.83-.233-.836-.834-.007-.767,0-1.534,0-2.391-.76,0-1.5,0-2.239,0-.793,0-.986-.193-.986-.979q0-12.038,0-24.076c0-.8.178-.974.985-.974h44.575c.789,0,.97.187.971.988,0,.725,0,1.45,0,2.236.831,0,1.611-.005,2.393,0,.607.005.825.225.83.835.006.767,0,1.534,0,2.389.78,0,1.539,0,2.3,0,.729,0,.926.194.926.914q0,12.1,0,24.192c0,.717-.205.925-.92.925q-22.345,0-44.69,0c-.716,0-.918-.207-.92-.925C-1980.521-1090.713-1980.519-1091.457-1980.519-1092.268Zm-5.069-11.273a7.5,7.5,0,0,1,6.658,6.652h16.3q6.825,0,13.649.006c.375,0,.556-.041.621-.5a7.262,7.262,0,0,1,6.1-6.044c.346-.049.441-.173.439-.506q-.015-4.579,0-9.158c0-.334-.092-.45-.44-.5a7.094,7.094,0,0,1-4.238-2.235,7.388,7.388,0,0,1-1.946-4.269h-30.517a1.479,1.479,0,0,0-.062.2,7.262,7.262,0,0,1-6.166,6.309c-.374.054-.415.218-.413.525.009,1.785,0,3.571,0,5.356Zm46.955,9.873v-23.224h-1.8v.7q0,9.79,0,19.581c0,.983-.134,1.114-1.139,1.114h-40.74v1.827Zm-40.489,3.2h43.724v-23.183h-1.813v20.245c0,1.025-.119,1.142-1.164,1.142h-40.747Zm-6.437-11.57v5.121h5.119A5.783,5.783,0,0,0-1985.56-1102.042Zm38.579,5.153h5.163v-5.163A5.833,5.833,0,0,0-1946.981-1096.889ZM-1985.558-1115c2.373.036,5.071-2.657,5.117-5.113h-5.117Zm43.721-5.109h-5.139a5.706,5.706,0,0,0,5.139,5.092Z"
                            fill="#fff"
                          ></path>
                          <path
                            id="Path_8"
                            data-name="Path 8"
                            d="M-1774.256-1045.419a8.9,8.9,0,0,1-8.906-8.924,8.9,8.9,0,0,1,8.937-8.891,8.9,8.9,0,0,1,8.877,8.894A8.9,8.9,0,0,1-1774.256-1045.419Zm-.014-16.4a7.475,7.475,0,0,0-7.479,7.509,7.489,7.489,0,0,0,7.51,7.483,7.492,7.492,0,0,0,7.48-7.514A7.478,7.478,0,0,0-1774.27-1061.823Z"
                            transform="translate(-189.449 -54.183)"
                            fill="#fff"
                          ></path>
                          <path
                            id="Path_9"
                            data-name="Path 9"
                            d="M-1704.7-1012.274v-2.531a2.787,2.787,0,0,1-2.278-1.017,2.675,2.675,0,0,1-.585-1.93c.08-1.382,1.154-2.35,2.844-2.5.084-.514-.082-1.275.787-1.229.734.039.625.687.643,1.082a11.412,11.412,0,0,1,1.776.728,2.375,2.375,0,0,1,1.1,2.094.661.661,0,0,1-.623.756c-.444.04-.7-.227-.767-.677-.148-.939-.55-1.282-1.469-1.257v2.5c1.854.243,2.847,1.189,2.857,2.7.011,1.561-.969,2.528-2.856,2.791-.058.514.079,1.19-.746,1.222-.466.018-.637-.3-.689-1.211-.284-.052-.582-.091-.871-.164a2.667,2.667,0,0,1-1.995-2.513c0-.464.208-.762.651-.787s.679.255.743.707C-1706.045-1012.622-1705.641-1012.281-1704.7-1012.274Zm-.013-3.955v-2.539c-.884.005-1.444.484-1.463,1.222S-1705.636-1016.28-1704.709-1016.228Zm1.448,3.944a1.267,1.267,0,0,0,1.466-1.254,1.276,1.276,0,0,0-1.466-1.244Z"
                            transform="translate(-259.72 -92.992)"
                            fill="#fff"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <span className="fw_500">Easy Access</span>
                  </div>
                </div>
                <div className="orange_slide">Annual Return: 3.1%</div>
              </label>
            </div>
            <div className="wrapper">
              <label className="container_app ">
                <input
                  value="1 Year Fixed Saver"
                  type="radio"
                  name="bond_options"
                  onChange={handleAccountOptionChange}
                />
                <div className="checkmark_app">
                  <div className="flex_column">
                    <div className="card_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        viewBox="0 0 74 75"
                      >
                        <g
                          id="Rectangle_12"
                          data-name="Rectangle 12"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        >
                          <rect
                            width="74"
                            height="75"
                            rx="15"
                            stroke="none"
                          ></rect>
                          <rect
                            x="1"
                            y="1"
                            width="72"
                            height="73"
                            rx="14"
                            fill="none"
                          ></rect>
                        </g>
                        <g
                          id="Group_16"
                          data-name="Group 16"
                          transform="translate(1997.478 1142.783)"
                        >
                          <path
                            id="Path_7"
                            data-name="Path 7"
                            d="M-1980.519-1092.268c-.854,0-1.621.006-2.388,0-.6-.006-.83-.233-.836-.834-.007-.767,0-1.534,0-2.391-.76,0-1.5,0-2.239,0-.793,0-.986-.193-.986-.979q0-12.038,0-24.076c0-.8.178-.974.985-.974h44.575c.789,0,.97.187.971.988,0,.725,0,1.45,0,2.236.831,0,1.611-.005,2.393,0,.607.005.825.225.83.835.006.767,0,1.534,0,2.389.78,0,1.539,0,2.3,0,.729,0,.926.194.926.914q0,12.1,0,24.192c0,.717-.205.925-.92.925q-22.345,0-44.69,0c-.716,0-.918-.207-.92-.925C-1980.521-1090.713-1980.519-1091.457-1980.519-1092.268Zm-5.069-11.273a7.5,7.5,0,0,1,6.658,6.652h16.3q6.825,0,13.649.006c.375,0,.556-.041.621-.5a7.262,7.262,0,0,1,6.1-6.044c.346-.049.441-.173.439-.506q-.015-4.579,0-9.158c0-.334-.092-.45-.44-.5a7.094,7.094,0,0,1-4.238-2.235,7.388,7.388,0,0,1-1.946-4.269h-30.517a1.479,1.479,0,0,0-.062.2,7.262,7.262,0,0,1-6.166,6.309c-.374.054-.415.218-.413.525.009,1.785,0,3.571,0,5.356Zm46.955,9.873v-23.224h-1.8v.7q0,9.79,0,19.581c0,.983-.134,1.114-1.139,1.114h-40.74v1.827Zm-40.489,3.2h43.724v-23.183h-1.813v20.245c0,1.025-.119,1.142-1.164,1.142h-40.747Zm-6.437-11.57v5.121h5.119A5.783,5.783,0,0,0-1985.56-1102.042Zm38.579,5.153h5.163v-5.163A5.833,5.833,0,0,0-1946.981-1096.889ZM-1985.558-1115c2.373.036,5.071-2.657,5.117-5.113h-5.117Zm43.721-5.109h-5.139a5.706,5.706,0,0,0,5.139,5.092Z"
                            fill="#fff"
                          ></path>
                          <path
                            id="Path_8"
                            data-name="Path 8"
                            d="M-1774.256-1045.419a8.9,8.9,0,0,1-8.906-8.924,8.9,8.9,0,0,1,8.937-8.891,8.9,8.9,0,0,1,8.877,8.894A8.9,8.9,0,0,1-1774.256-1045.419Zm-.014-16.4a7.475,7.475,0,0,0-7.479,7.509,7.489,7.489,0,0,0,7.51,7.483,7.492,7.492,0,0,0,7.48-7.514A7.478,7.478,0,0,0-1774.27-1061.823Z"
                            transform="translate(-189.449 -54.183)"
                            fill="#fff"
                          ></path>
                          <path
                            id="Path_9"
                            data-name="Path 9"
                            d="M-1704.7-1012.274v-2.531a2.787,2.787,0,0,1-2.278-1.017,2.675,2.675,0,0,1-.585-1.93c.08-1.382,1.154-2.35,2.844-2.5.084-.514-.082-1.275.787-1.229.734.039.625.687.643,1.082a11.412,11.412,0,0,1,1.776.728,2.375,2.375,0,0,1,1.1,2.094.661.661,0,0,1-.623.756c-.444.04-.7-.227-.767-.677-.148-.939-.55-1.282-1.469-1.257v2.5c1.854.243,2.847,1.189,2.857,2.7.011,1.561-.969,2.528-2.856,2.791-.058.514.079,1.19-.746,1.222-.466.018-.637-.3-.689-1.211-.284-.052-.582-.091-.871-.164a2.667,2.667,0,0,1-1.995-2.513c0-.464.208-.762.651-.787s.679.255.743.707C-1706.045-1012.622-1705.641-1012.281-1704.7-1012.274Zm-.013-3.955v-2.539c-.884.005-1.444.484-1.463,1.222S-1705.636-1016.28-1704.709-1016.228Zm1.448,3.944a1.267,1.267,0,0,0,1.466-1.254,1.276,1.276,0,0,0-1.466-1.244Z"
                            transform="translate(-259.72 -92.992)"
                            fill="#fff"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <span className="fw_500">1 Year Fixed Saver</span>
                  </div>
                </div>
                <div className="orange_slide">Annual Return: 4.2%</div>
              </label>
            </div>
            <div className="wrapper">
              <label className="container_app ">
                <input
                  value="3 Year Fixed Saver"
                  type="radio"
                  name="bond_options"
                  onChange={handleAccountOptionChange}
                />
                <div className="checkmark_app">
                  <div className="flex_column">
                    <div className="card_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        viewBox="0 0 74 75"
                      >
                        <g
                          id="Rectangle_12"
                          data-name="Rectangle 12"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        >
                          <rect
                            width="74"
                            height="75"
                            rx="15"
                            stroke="none"
                          ></rect>
                          <rect
                            x="1"
                            y="1"
                            width="72"
                            height="73"
                            rx="14"
                            fill="none"
                          ></rect>
                        </g>
                        <g
                          id="Group_16"
                          data-name="Group 16"
                          transform="translate(1997.478 1142.783)"
                        >
                          <path
                            id="Path_7"
                            data-name="Path 7"
                            d="M-1980.519-1092.268c-.854,0-1.621.006-2.388,0-.6-.006-.83-.233-.836-.834-.007-.767,0-1.534,0-2.391-.76,0-1.5,0-2.239,0-.793,0-.986-.193-.986-.979q0-12.038,0-24.076c0-.8.178-.974.985-.974h44.575c.789,0,.97.187.971.988,0,.725,0,1.45,0,2.236.831,0,1.611-.005,2.393,0,.607.005.825.225.83.835.006.767,0,1.534,0,2.389.78,0,1.539,0,2.3,0,.729,0,.926.194.926.914q0,12.1,0,24.192c0,.717-.205.925-.92.925q-22.345,0-44.69,0c-.716,0-.918-.207-.92-.925C-1980.521-1090.713-1980.519-1091.457-1980.519-1092.268Zm-5.069-11.273a7.5,7.5,0,0,1,6.658,6.652h16.3q6.825,0,13.649.006c.375,0,.556-.041.621-.5a7.262,7.262,0,0,1,6.1-6.044c.346-.049.441-.173.439-.506q-.015-4.579,0-9.158c0-.334-.092-.45-.44-.5a7.094,7.094,0,0,1-4.238-2.235,7.388,7.388,0,0,1-1.946-4.269h-30.517a1.479,1.479,0,0,0-.062.2,7.262,7.262,0,0,1-6.166,6.309c-.374.054-.415.218-.413.525.009,1.785,0,3.571,0,5.356Zm46.955,9.873v-23.224h-1.8v.7q0,9.79,0,19.581c0,.983-.134,1.114-1.139,1.114h-40.74v1.827Zm-40.489,3.2h43.724v-23.183h-1.813v20.245c0,1.025-.119,1.142-1.164,1.142h-40.747Zm-6.437-11.57v5.121h5.119A5.783,5.783,0,0,0-1985.56-1102.042Zm38.579,5.153h5.163v-5.163A5.833,5.833,0,0,0-1946.981-1096.889ZM-1985.558-1115c2.373.036,5.071-2.657,5.117-5.113h-5.117Zm43.721-5.109h-5.139a5.706,5.706,0,0,0,5.139,5.092Z"
                            fill="#fff"
                          ></path>
                          <path
                            id="Path_8"
                            data-name="Path 8"
                            d="M-1774.256-1045.419a8.9,8.9,0,0,1-8.906-8.924,8.9,8.9,0,0,1,8.937-8.891,8.9,8.9,0,0,1,8.877,8.894A8.9,8.9,0,0,1-1774.256-1045.419Zm-.014-16.4a7.475,7.475,0,0,0-7.479,7.509,7.489,7.489,0,0,0,7.51,7.483,7.492,7.492,0,0,0,7.48-7.514A7.478,7.478,0,0,0-1774.27-1061.823Z"
                            transform="translate(-189.449 -54.183)"
                            fill="#fff"
                          ></path>
                          <path
                            id="Path_9"
                            data-name="Path 9"
                            d="M-1704.7-1012.274v-2.531a2.787,2.787,0,0,1-2.278-1.017,2.675,2.675,0,0,1-.585-1.93c.08-1.382,1.154-2.35,2.844-2.5.084-.514-.082-1.275.787-1.229.734.039.625.687.643,1.082a11.412,11.412,0,0,1,1.776.728,2.375,2.375,0,0,1,1.1,2.094.661.661,0,0,1-.623.756c-.444.04-.7-.227-.767-.677-.148-.939-.55-1.282-1.469-1.257v2.5c1.854.243,2.847,1.189,2.857,2.7.011,1.561-.969,2.528-2.856,2.791-.058.514.079,1.19-.746,1.222-.466.018-.637-.3-.689-1.211-.284-.052-.582-.091-.871-.164a2.667,2.667,0,0,1-1.995-2.513c0-.464.208-.762.651-.787s.679.255.743.707C-1706.045-1012.622-1705.641-1012.281-1704.7-1012.274Zm-.013-3.955v-2.539c-.884.005-1.444.484-1.463,1.222S-1705.636-1016.28-1704.709-1016.228Zm1.448,3.944a1.267,1.267,0,0,0,1.466-1.254,1.276,1.276,0,0,0-1.466-1.244Z"
                            transform="translate(-259.72 -92.992)"
                            fill="#fff"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <span className="fw_500">3 Year Fixed Saver</span>
                  </div>
                </div>
                <div className="orange_slide">Annual Return: 4.6%</div>
              </label>
            </div>
            <div className="wrapper">
              <label className="container_app ">
                <input
                  value="5 Year Fixed Saver"
                  type="radio"
                  name="bond_options"
                  onChange={handleAccountOptionChange}
                />
                <div className="checkmark_app">
                  <div className="flex_column">
                    <div className="card_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        viewBox="0 0 74 75"
                      >
                        <g
                          id="Rectangle_12"
                          data-name="Rectangle 12"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        >
                          <rect
                            width="74"
                            height="75"
                            rx="15"
                            stroke="none"
                          ></rect>
                          <rect
                            x="1"
                            y="1"
                            width="72"
                            height="73"
                            rx="14"
                            fill="none"
                          ></rect>
                        </g>
                        <g
                          id="Group_16"
                          data-name="Group 16"
                          transform="translate(1997.478 1142.783)"
                        >
                          <path
                            id="Path_7"
                            data-name="Path 7"
                            d="M-1980.519-1092.268c-.854,0-1.621.006-2.388,0-.6-.006-.83-.233-.836-.834-.007-.767,0-1.534,0-2.391-.76,0-1.5,0-2.239,0-.793,0-.986-.193-.986-.979q0-12.038,0-24.076c0-.8.178-.974.985-.974h44.575c.789,0,.97.187.971.988,0,.725,0,1.45,0,2.236.831,0,1.611-.005,2.393,0,.607.005.825.225.83.835.006.767,0,1.534,0,2.389.78,0,1.539,0,2.3,0,.729,0,.926.194.926.914q0,12.1,0,24.192c0,.717-.205.925-.92.925q-22.345,0-44.69,0c-.716,0-.918-.207-.92-.925C-1980.521-1090.713-1980.519-1091.457-1980.519-1092.268Zm-5.069-11.273a7.5,7.5,0,0,1,6.658,6.652h16.3q6.825,0,13.649.006c.375,0,.556-.041.621-.5a7.262,7.262,0,0,1,6.1-6.044c.346-.049.441-.173.439-.506q-.015-4.579,0-9.158c0-.334-.092-.45-.44-.5a7.094,7.094,0,0,1-4.238-2.235,7.388,7.388,0,0,1-1.946-4.269h-30.517a1.479,1.479,0,0,0-.062.2,7.262,7.262,0,0,1-6.166,6.309c-.374.054-.415.218-.413.525.009,1.785,0,3.571,0,5.356Zm46.955,9.873v-23.224h-1.8v.7q0,9.79,0,19.581c0,.983-.134,1.114-1.139,1.114h-40.74v1.827Zm-40.489,3.2h43.724v-23.183h-1.813v20.245c0,1.025-.119,1.142-1.164,1.142h-40.747Zm-6.437-11.57v5.121h5.119A5.783,5.783,0,0,0-1985.56-1102.042Zm38.579,5.153h5.163v-5.163A5.833,5.833,0,0,0-1946.981-1096.889ZM-1985.558-1115c2.373.036,5.071-2.657,5.117-5.113h-5.117Zm43.721-5.109h-5.139a5.706,5.706,0,0,0,5.139,5.092Z"
                            fill="#fff"
                          ></path>
                          <path
                            id="Path_8"
                            data-name="Path 8"
                            d="M-1774.256-1045.419a8.9,8.9,0,0,1-8.906-8.924,8.9,8.9,0,0,1,8.937-8.891,8.9,8.9,0,0,1,8.877,8.894A8.9,8.9,0,0,1-1774.256-1045.419Zm-.014-16.4a7.475,7.475,0,0,0-7.479,7.509,7.489,7.489,0,0,0,7.51,7.483,7.492,7.492,0,0,0,7.48-7.514A7.478,7.478,0,0,0-1774.27-1061.823Z"
                            transform="translate(-189.449 -54.183)"
                            fill="#fff"
                          ></path>
                          <path
                            id="Path_9"
                            data-name="Path 9"
                            d="M-1704.7-1012.274v-2.531a2.787,2.787,0,0,1-2.278-1.017,2.675,2.675,0,0,1-.585-1.93c.08-1.382,1.154-2.35,2.844-2.5.084-.514-.082-1.275.787-1.229.734.039.625.687.643,1.082a11.412,11.412,0,0,1,1.776.728,2.375,2.375,0,0,1,1.1,2.094.661.661,0,0,1-.623.756c-.444.04-.7-.227-.767-.677-.148-.939-.55-1.282-1.469-1.257v2.5c1.854.243,2.847,1.189,2.857,2.7.011,1.561-.969,2.528-2.856,2.791-.058.514.079,1.19-.746,1.222-.466.018-.637-.3-.689-1.211-.284-.052-.582-.091-.871-.164a2.667,2.667,0,0,1-1.995-2.513c0-.464.208-.762.651-.787s.679.255.743.707C-1706.045-1012.622-1705.641-1012.281-1704.7-1012.274Zm-.013-3.955v-2.539c-.884.005-1.444.484-1.463,1.222S-1705.636-1016.28-1704.709-1016.228Zm1.448,3.944a1.267,1.267,0,0,0,1.466-1.254,1.276,1.276,0,0,0-1.466-1.244Z"
                            transform="translate(-259.72 -92.992)"
                            fill="#fff"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <span className="fw_500">5 Year Fixed Saver</span>
                  </div>
                </div>
                <div className="orange_slide">Annual Return: 5.1%</div>
              </label>
            </div>
          </div>
        </div>
        <div className="wrap_two">
          <div className="wrap">
            <div className="wrap_header">
              <div className="title">Account Information</div>
            </div>
            <div className="wrap_body">
              <div className="wrap_item">
                <p className="wrap_text">Annual Return %:</p>
                <span className="span_text">
                  {(interestRate * 100).toFixed(2)}%
                </span>
              </div>
            </div>
          </div>
          <div className="wrap">
            <div className="wrap_header">
              <div className="title">Your Result</div>
            </div>
            <div className="wrap_body">
              <div className="wrap_item">
                <p className="wrap_text">Initial Investment</p>
                <span className="span_text">${formatNumber(amount)}</span>
              </div>
              <div className="wrap_item">
                <p className="wrap_text">Daily Return:</p>
                <span className="span_text">${results.dailyReturn}</span>
              </div>
              <div className="wrap_item">
                <p className="wrap_text">Monthly Return:</p>
                <span className="span_text">${results.monthlyReturn}</span>
              </div>
              <div className="wrap_item">
                <p className="wrap_text">Annual Return:</p>
                <span className="span_text">${results.annualReturn}</span>
              </div>
              <div className="wrap_item">
                <p className="wrap_text">
                  Total Maturity Return (Without Compounding):
                </p>
                <span className="span_text">${results.totalMaturityReturn}</span>
              </div>
              <div className="wrap_item">
                <p className="wrap_text">Total (Without Compounding):</p>
                <span className="span_text">
                  ${results.total}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
