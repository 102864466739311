import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import "./style.css";
import { formatNumber, getAuthUser } from "../../firebase/firestore";

export default function TransactionTable() {
  const [transactions, setTransactions] = useState([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    
    const user = getAuthUser();

    if (user) {
      setUserId(user);
    }
  }, []); // This effect runs once when the component mounts to get the userId

  useEffect(() => {
    if (!userId) return; // Don't do anything if userId is not set

    // Define a function to set up a real-time listener on transactions
    const setupRealtimeListener = () => {
      const userDocRef = doc(db, "users", userId);
      const transactionsCollectionRef = collection(userDocRef, "transactions");

      const q = query(transactionsCollectionRef, orderBy("date", "desc")); // assuming you want to order by date

      // This onSnapshot will be triggered whenever there's a change in the transactions collection
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedTransactions = snapshot.docs.map((doc) => doc.data());
        setTransactions(fetchedTransactions);
      });

      // Return the unsubscribe function to clean up the listener when the component is unmounted
      return unsubscribe;
    };

    // Call the function and store the unsubscribe function
    const unsubscribeFromRealtimeUpdates = setupRealtimeListener();

    // Cleanup the listener when the component is unmounted
    return () => {
      unsubscribeFromRealtimeUpdates();
    };
  }, [userId]); // This effect will re-run whenever userId changes

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#ffcc00";
      case "Approved":
        return "#3d8d00";
      default:
        return "#ff0000";
    }
  };

  return (
    <section className="transactionTable">
      <div className="table_header">
        <h2 className="title">Transactions</h2>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Account</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.type}</td>
              <td>{transaction.date}</td>
              <td>$ {formatNumber(transaction.amount)}</td>
              <td>{transaction.accountType}</td>
              <td
                style={{
                  backgroundColor: getStatusColor(transaction.status),
                  color: "#fff",
                }}
              >
                {transaction.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
