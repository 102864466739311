import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "../src/components/Auth/Login";
import WelcomeScreen from "../src/components/Auth/WelcomeScreen";
import Register from "./components/Auth/Register";
import { useAuth } from "./authContext";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ChangePassword from "./components/Auth/ChangePassword";
import MyAccounts from "./components/MyAccounts";
import Transactions from "./components/Transactions";
import AccountDetails from "./components/AccountDetails";
import InterestCalculator from "./components/InterestCalculator";
import LoadingScreen from "./components/LoadingScreen";
import Bonds from "./components/Bonds";
import NotificationPage from "./components/Notifications";
import FixedTermDeposits from "./components/FixedTerm";
import IPOs from "./components/IPOs";
import ProtectedRoute from "./protectedRoute";
import VerifyEmail from "./components/Auth/VerifyEmail";
import HomePage from "./components/HomePage";
import Chat from "./components/Chat";
import MarketAnalysis from "./components/MarketAnalysis";
import PhoneVerification from "./components/Auth/PhoneValidation";
import AuthAction from "./components/Auth/AuthAction";
import ErrorPage from "./pages/Error";

function App() {
  const { loadingAuthState } = useAuth();

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" index element={<Login />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/phone-verification" element={<PhoneVerification />} />
          <Route path="/auth-action" element={<AuthAction />} />
          <Route
            path="/verify-email"
            element={
              <ProtectedRoute>
                <VerifyEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/onboard"
            element={
              <ProtectedRoute>
                <WelcomeScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="my-accounts"
              element={
                <ProtectedRoute>
                  <MyAccounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="bonds"
              element={
                <ProtectedRoute>
                  <Bonds />
                </ProtectedRoute>
              }
            />
            <Route
              path="ipos"
              element={
                <ProtectedRoute>
                  <IPOs />
                </ProtectedRoute>
              }
            />
            <Route
              path="fixed-term-deposits"
              element={
                <ProtectedRoute>
                  <FixedTermDeposits />
                </ProtectedRoute>
              }
            />
            <Route
              path="transactions"
              element={
                <ProtectedRoute>
                  <Transactions />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="stock-trading"
              element={
                <ProtectedRoute>
                  <StockTrading />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="account-details"
              element={
                <ProtectedRoute>
                  <AccountDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="chat"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="market-analysis"
              element={
                <ProtectedRoute>
                  <MarketAnalysis />
                </ProtectedRoute>
              }
            />
            <Route
              path="interest-calculator"
              element={
                <ProtectedRoute>
                  <InterestCalculator />
                </ProtectedRoute>
              }
            />
            <Route
              path="notifications"
              element={
                <ProtectedRoute>
                  <NotificationPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
      {loadingAuthState && <LoadingScreen />}
    </div>
  );
}

export default App;
