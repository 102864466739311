import React, { useState } from "react";
import "./style.css";
import TickerWidget from "./Widgets/Ticker";
import MarketMovers from "./Widgets/MarketMovers";
import ChartWidget from "./Widgets/ChartWidget";
import SearchBar from "./Widgets/SearchBar";
import TechnicalAnalysisWidget from "./Widgets/TechnicalAnalysis";
import SymbolInfoWidget from "./Widgets/SymbolInfoWidget";
import LoadingScreen from "../LoadingScreen";
import StockHeatmapWidget from "./Widgets/StockHeatmapWidget";
import MiniChartWidget from "./Widgets/MiniChartWidget";

const MarketAnalysis = () => {
  const [selectedSymbol, setSelectedSymbol] = useState("NASDAQ:AAPL");
  const [isTechnicalAnalysisLoading, setIsTechnicalAnalysisLoading] =
    useState(false);
  const [isChartWidgetLoading, setIsChartWidgetLoading] = useState(false);
  const [isSymbolInfoLoading, setIsSymbolInfoLoading] = useState(false);

  const handleSearch = (query) => {
    setSelectedSymbol(`NASDAQ:${query.toUpperCase()}`);
  };

  return (
    <div className="market_wrapper">
      <div className="container_fluid">
        <TickerWidget />

        <div className="analysis_card">
          <div className="market_activities">
            <div className="activities_grid">
              <div className="search_container">
                <SearchBar onSearch={handleSearch} />
                <MiniChartWidget symbol={selectedSymbol} />
              </div>

              {/* <SymbolInfoWidget
                 symbol={selectedSymbol}
                 setIsSymbolInfoLoading={setIsSymbolInfoLoading}
              /> */}

              <ChartWidget
                symbol={selectedSymbol}
                setIsChartWidgetLoading={setIsChartWidgetLoading}
              />
              <StockHeatmapWidget />
            </div>

            <div className="activities_grid">
              <MarketMovers />

              <TechnicalAnalysisWidget
                symbol={selectedSymbol}
                setIsTechnicalAnalysisLoading={setIsTechnicalAnalysisLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketAnalysis;
