import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/firebase";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/white_logo.png";
import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import { addLogNotification } from "../../../firebase/firestore";
import { IoEye, IoEyeOff } from "react-icons/io5";
import "./style.css";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await setPersistence(auth, browserSessionPersistence);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userUID = user.uid;

      // Fetch the user's details from Firestore
      const userRef = doc(db, "users", userUID);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        setError("User not found. Please sign up.");
        setTimeout(() => {
          setError("");
        }, 4000);
        setIsLoading(false);
        return;
      }

      const userData = userDoc.data();
      const nameParts = userData.fullName;

      // Store the user's first name in secure storage
      secureLocalStorage.setItem("user_firstName", nameParts);

      // Check if it's a joint account and store the secondary account holder's name
      if (userData.jointAccount) {
        const secondaryUserData = userDoc.data();
        const secondaryNameParts = secondaryUserData.secondaryAccountHolder;

        secureLocalStorage.setItem("secondaryHolderName", secondaryNameParts);
      }

      // Update the user's 'isLoggedIn' field in Firestore
      await updateDoc(userRef, { isLoggedIn: true });
      // const adminNotification = `User ${nameParts} with UID of ${user.uid} just logged in.`;
      await addLogNotification(userRef, user);

      setIsLoading(false);
      navigate("/onboard");
    } catch (error) {
      if (error.code === "auth/invalid-login-credentials") {
        setError("User not found. Please sign up.");
        setTimeout(() => {
          setError("");
        }, 4000);
        setIsLoading(false);
        return;
      }
      setError(error.message);
      setIsLoading(false);
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        return;
      } else {
        navigate("/");
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [auth]);

  return (
    <section className="login_container">
      <div className="login_img">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="login_form">
        <img src={logo} alt="Logo" className="logo" />
        <div className="header">
          <h1 className="title">Sign In</h1>
          <p className="subtitle">
            To keep connected with us please login with your personal info.
          </p>
        </div>
        <div className="form_wrap" >
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="input_field"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="password_field">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              className="pass_field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {showPassword ? (
              <IoEye
                className="password_icon"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <IoEyeOff
                className="password_icon"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          <div className="flex">
            <Link to="/forgot-password" className="forgot_password">
              Forgot password?
            </Link>
          </div>
          {error && <p className="error_msg">{error}</p>}
          {isLoading ? (
            <button className="signin_btn" >
              <div className="spinner"></div>
            </button>
          ) : (
            <button className="signin_btn" onClick={handleLogin}>
              Sign In
            </button>
          )}

          <div className="signup_info">
            <p className="text">Do not have an account?</p>{" "}
            <Link to={"/signup"} className="signup_text">
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
