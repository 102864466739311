import React from "react";
import "./style.css";

export default function EarlyWithDrawalAert({
  isOpen,
  onConfirm,
  onClose,
  terms,
  isLoading
}) {
  const styles = {
    modalBody: {
      marginTop: "20px",
    },
    sectionHeader: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "1rem",
      marginTop: "1rem",
    },
    title: {
      fontSize: "28px",
      fontWeight: "600",
      color: "#333333",
    },
    text: {
      marginBottom: "10px",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "500",
    },
    conditionsList: {
      marginBottom: "20px",
      paddingLeft: "20px",
    },
  };
  return (
    isOpen && (
      <div className="modal_overlay">
        <div className="modal">
          <div style={styles.sectionHeader}>
            <h2 style={styles.title}>Early Withdrawal Alert!</h2>
          </div>
          <div style={styles.modalBody}>
            <p style={styles.text}>
              You are initiating an early withdrawal from your term deposit,
              which may incur:
            </p>
            <ol style={styles.conditionsList}>
              <li>
                An early withdrawal penalty (refer to terms and conditions for
                details).
              </li>
              <li>A flat administration fee of $30.</li>
            </ol>
            <p style={styles.text}>Are you sure you want to continue?</p>
          </div>
          <div className="buttons_wrap">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onConfirm(terms);
              }}
              className="submit_btn"
            >
              Withdraw
            </button>
            {
              isLoading && (
                <div className="spinner"></div>
              )
            }
            <button onClick={onClose} className="cancel_btn">
              No
            </button>
          </div>
        </div>
      </div>
    )
  );
}
