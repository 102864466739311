import React, { useState } from "react";
import {
  buyBonds,
  formatNumber,
  getAuthUser,
  getCurrentDate,
} from "../../../firebase/firestore";
import CurrencyInput from "react-currency-input-field";
import "./style.css";
import Swal from "sweetalert2";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { set } from "date-fns";

export default function BuyBondsModal({ isOpen, onClose, bond }) {
  const [bondsAmount, setBondsAmount] = useState(0);
  const [userName, setUserName] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleBuyBonds = async () => {
    const minimumInvestmentAmount = bond.minimumAmount;
    if (minimumInvestmentAmount > bondsAmount || bondsAmount === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Cannot buy less than $${minimumInvestmentAmount}`,
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
  
    // Assuming getAuthUser() returns the user's UID
    const uid = getAuthUser();
    if (!uid) {
      console.error("No user ID found");
      return;
    }
  
    // Fetch user details using the user ID
    const db = getFirestore(); // Ensure you initialize Firestore
    const userDocRef = doc(db, "users", uid);
    let userName = "Unknown User"; // Default user name
  
    try {
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (userDocSnapshot.exists()) {
        const userDetails = userDocSnapshot.data();
        userName = userDetails.fullName; 
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  
    // Calculate how many bonds the user is buying
    const amountAsNumber = parseFloat(bondsAmount);
    const numberOfBondsBought = amountAsNumber / minimumInvestmentAmount;
  
    // Create bond data including the userName
    const bondData = {
      amountRequested: amountAsNumber,
      image: bond.image,
      type: bond.type,
      couponRate: bond.couponRate,
      companyWebsite: bond.companyWebsite,
      isin: bond.isin,
      maturityDate: bond.maturityDate,
      purchaseDate: getCurrentDate(),
      currentValue: amountAsNumber,
      issuerName: bond.issuerName,
      sector: bond.sector,
      couponFrequency: bond.couponFrequency,
      minimumAmount: bond.minimumAmount,
      quantity: numberOfBondsBought,
      userName: userName, // Use the fetched userName
    };
  
    setIsLoading(true);
  
    try {
      await buyBonds(uid, bondData);
  
      setMessage("Request has been sent.");
      setTimeout(() => {
        setMessage("");
        setBondsAmount(0);
        onClose();
      }, 3000);
    } catch (error) {
      setError(`There was an issue sending the request. Try again later.`);
      console.error(error);
      setTimeout(() => {
        setError("");
        setBondsAmount(0);
      }, 4000);
    }
  
    setIsLoading(false);
  };
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal_overlay" onClick={(e) => e.stopPropagation()}>
      <div className="modal">
        <div className="section_header">
          <h2 className="title">
            Purchase of <br />
            {bond.issuerName}
          </h2>
          <div className="subtitle">
            <span>{bond.type}</span>
          </div>
        </div>
        <div className="bondSection_body">
          <div className="more_dets">
            <div className="maturity_row">
              <p className="bold_text">Sector:</p>
              <span className="reg_text">{bond.sector}</span>
            </div>

            <div className="">
              <div className="maturity_row">
                <p className="bold_text">Maturity Date:</p>
                <span className="reg_text">{bond.maturityDate}</span>
              </div>
              <div className="maturity_row">
                <p className="bold_text">Minimum Amount:</p>
                <span className="reg_text">
                  $ {formatNumber(bond.minimumAmount)}
                </span>
              </div>
            </div>
          </div>
          <div className="input_group">
            <label htmlFor="title">Input Amount:</label>
            <CurrencyInput
              decimalSeparator="."
              prefix="$"
              name="bondsAmount"
              placeholder="$0"
              defaultValue={bondsAmount}
              decimalsLimit={2}
              onValueChange={(value) => {
                const formattedValue = parseFloat(value).toFixed(2);
                setBondsAmount(parseFloat(formattedValue)); // Store as a number
              }}
            />
          </div>
        </div>
        {message && <p className="success_msg">{message}</p>}
        {error && <p className="error_msg">{error}</p>}
        <div className="buttons_wrap">
          <button
            onClick={() => {
              handleBuyBonds();
            }}
            className="submit_btn"
          >
            Request
          </button>
          {isLoading && (
            <div className="spinner" style={{ margin: "0 auto" }}></div>
          )}
          <button onClick={() => {
            setBondsAmount(0);
            onClose();
          
          }} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
