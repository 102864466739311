import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsBank } from "react-icons/bs";
import "./style.css";
import AccountInfo from "../AccountInfo";
import BankingDetails from "../BankingDetails";
import UploadDoc from "../UploadDoc";
import ChangePassword from "../ChangePassword";

export default function AccountDetails() {
  const [activeTab, setActiveTab] = useState("accountInfo");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="account_layout">
      <div className="account_menu">
        <ul className="account_list">
          <li
            className={`account_item ${
              activeTab === "accountInfo" ? "active" : ""
            }`}
            onClick={() => handleTabClick("accountInfo")}
          >
            <span>
              <GoPencil />
              Account Information
            </span>
            <FaAngleRight size={20}/>
          </li>
          <li
            className={`account_item ${
              activeTab === "changePassword" ? "active" : ""
            }`}
            onClick={() => handleTabClick("changePassword")}
          >
            <span>
              <RiLockPasswordFill />
              Change Password
            </span>
            <FaAngleRight size={20}/>
          </li>
          <li
            className={`account_item ${
              activeTab === "documents" ? "active" : ""
            }`}
            onClick={() => handleTabClick("documents")}
          >
            <span>
              <IoNewspaperOutline className="icon" />
              Documents (KYC)
            </span>
            <FaAngleRight size={20}/>
          </li>
          <li
            className={`account_item ${
              activeTab === "bankingDetails" ? "active" : ""
            }`}
            onClick={() => handleTabClick("bankingDetails")}
          >
            <span>
              <BsBank className="icon" />
              Banking Details
            </span>
            <FaAngleRight size={20}/>
          </li>
        </ul>
      </div>

      <div className="content__area">
        {activeTab === "accountInfo" && <AccountInfoTab />}
        {activeTab === "changePassword" && <ChangePasswordTab />}
        {activeTab === "documents" && <DocumentsTab />}
        {activeTab === "bankingDetails" && <BankingDetailsTab />}
      </div>
    </div>
  );
}

// Individual Tab Components

const AccountInfoTab = () => {
  return <AccountInfo />;
};

const ChangePasswordTab = () => {
  return <ChangePassword/>;
};

const DocumentsTab = () => {
  return <UploadDoc/>;
};

const BankingDetailsTab = () => {
  return <BankingDetails/>;
};
