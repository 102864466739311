import React, { useEffect, useState } from "react";
import chat_icon from "../../assets/live_chat.png";
import {
  closeChat,
  fetchChats,
  getAuthUser,
  getUser,
  sendChat,
} from "../../firebase/firestore";
import { format, isToday, isYesterday } from "date-fns";
import { db } from "../../firebase/firebase";
import Swal from "sweetalert2";
import "./styles.css";

export default function Chat() {
  const [chat, setChat] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
 
  useEffect(() => {
    const userUid = getAuthUser(); // Get the current user's UID
    const unsubscribe = fetchChats(db, userUid, setMessages);

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const handleSendChat = async (e) => {
    e.preventDefault();
    const userUid = getAuthUser();
    
    const userData = await getUser(userUid);
    const data = userData[0];
    const fullName = data.fullName;
    if (chat === "") {
        return;
    }
    
    setIsLoading(true);
    try {
      await sendChat(userUid, chat, fullName);
      setChat("");
    } catch (error) {
      console.error("Error sending chat:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatChange = (e) => {
    setChat(e.target.value);
  };

  const handleCloseChat = async (uid) => {
    if (messages.length === 0) {
        return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to close this chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#008000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const  uid = getAuthUser();
         
          await closeChat(db, uid);
          Swal.fire("Closed!", "The chat has been closed.", "success");
        } catch (err) {
          console.error(err);
          Swal.fire("Error", "Failed to close the chat", "error");
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const formatTimestamp = (timeStamp) => {
    if (!timeStamp) return "";

    const date = timeStamp.toDate(); // Convert Firestore timestamp to JavaScript Date object

    if (isToday(date)) {
      // If the message was sent today, return only the time
      return format(date, "p"); // 'p' is for the local time format
    } else if (isYesterday(date)) {
      // If the message was sent yesterday, return 'Yesterday'
      return "Yesterday";
    } else {
      // Otherwise, return the full date
      return format(date, "PPP"); // 'PPP' is for the longer date format, e.g., Jun 20, 2020
    }
  };

  return (
    <section className="chatPage_wrapper">
      <header>
        <div className="header_banner">
          <h4>Live Chat</h4>
        </div>
        <div className="chatPage_header">
          <div className="chatPage_icon">
            <img src={chat_icon} alt="chat icon" />
          </div>
          <div className="chatPage_title">
            <h4>
              Do you need help?
              <br />
              Please send us a message!
            </h4>
          </div>
        </div>
      </header>
        <div className="chatPage_chats">
            {messages.length === 0 ? (
            <p className=""></p>
            ) : (
                messages.map((message, index) => (
                    <p
                      key={index}
                      className={`chat ${message.user === "client" ? "user" : "admin"}`}
                    >
                      <span className="chatName">
                        {message.user === "client" ? "You" : "Service Desk"}:
                      </span>
                      <span className="chatMsg">{message.chat}</span>
                      <span className="timeStamp">
                        {formatTimestamp(message.timeStamp)}
                      </span>
                    </p>
                  ))
            )
            }
          
        </div>
        <div className="chatPage_chatbox">
          <textarea
            className="chatbox_banner"
            placeholder="Type here..."
            onChange={handleChatChange}
            value={chat}
          />
          <div className="btn_wrapper">
            <button
              className="chatbox_button"
              onClick={handleSendChat}
              type="submit"
            >
              {isLoading ? "Sending..." : "Send"}
            </button>
            <button className="close_button" onClick={handleCloseChat}>
              {isLoading ? "Closing..." : "Close Chat"}
            </button>
          </div>
        </div>
    </section>
  );
}
