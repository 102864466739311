import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  getDownloadURL as getStorageDownloadURL,
  deleteObject,
} from "firebase/storage";
import { auth, db, storage } from "../../firebase/firebase";
import { uploadFile } from "../../firebase/storage";
import Swal from "sweetalert2";
import "./style.css";
import { getAuthUser } from "../../firebase/firestore";

export default function UploadDoc() {
  const [fileDescription, setFileDescription] = useState("");
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("No user is currently signed in.");
      setIsLoading(false);
      return;
    }
  
    const userId = getAuthUser();
  
    try {
      const userDocCollectionRef = collection(db, "users", userId, "docs");
      const snapshot = await getDocs(userDocCollectionRef);
  
      // Remove old file from storage and Firestore if it exists
      if (!snapshot.empty) {
        const docId = snapshot.docs[0].id;
        const docData = snapshot.docs[0].data();
  
        if (docData.downloadURL && typeof docData.downloadURL === 'string' && docData.downloadURL.includes('firebaseapp.com/o/')) {
          const oldFilePath = docData.downloadURL.split('firebaseapp.com/o/')[1].split('?')[0];
          const oldFileRef = ref(storage, decodeURIComponent(oldFilePath));
          await deleteObject(oldFileRef);
        } else {
          console.warn("Unexpected document downloadURL format:", docData.downloadURL);
        }
      }
  
      // Now, add the new file to Firebase Storage and its metadata to Firestore
      const storagePath = await uploadFile(userId, file, fileDescription);
      const downloadURL = await getDownloadURL(storagePath);
  
      const docData = {
        fileDescription,
        downloadURL,
      };
  
      await addDoc(userDocCollectionRef, docData);
  
      // Show a success message to the user
      Swal.fire({
        icon: "success",
        title: "Uploaded!",
        text: `Document uploaded successfully!`,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {
      console.error("Error during file upload or Firestore save:", error);
  
      // Show an error message to the user
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `Failed to save document. Please try again.`,
        showConfirmButton: false,
        timer: 2000,
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSuccessMessage("");
        setError("");
      }, 3000);
    }
  };

  useEffect(() => {
    const fetchExistingDocument = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error("No user is currently signed in.");
        return;
      }
      const userId = getAuthUser();

      const userDocCollectionRef = collection(db, "users", userId, "docs");
      try {
        const querySnapshot = await getDocs(userDocCollectionRef);
        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data();
          setFileDescription(docData.fileDescription);
          setFileURL(docData.downloadURL);
        } else {
          return;
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchExistingDocument();
  }, []);

  return (
    <section className="uploadDoc_section">
      <div className="section_header">
        <h2 className="title">Upload Document</h2>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>

      <form action="" className="info_form" onSubmit={handleUpload}>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="file_description">
              File Description (i.e Passport):
            </label>
            <input
              type="text"
              name="file_description"
              className="input_field"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form_group">
          <div className="input_group">
            <input type="file" onChange={handleFileChange} required/>
          </div>
        </div>

        {error && <p className="error_msg">{error}</p>}
        {successMessage && <p className="success_msg">{successMessage}</p>}

        {isLoading ? (
          <button className="submit_btn" type="submit" disabled>
            <div className="spinner"></div>
          </button>
        ) : (
          <button className="submit_btn" type="submit">
            Save Document
          </button>
        )}
      </form>
    </section>
  );
}
