import React, { useEffect } from "react";
import { RiCloseFill } from "react-icons/ri";
import "./style.css";

export default function PhoneVerification({
  isOpen,
  onClose,
  onVerify,
  onResend,
  isLoading,
  error,
  successMessage,
  canResend,
  counter,
  setCounter,
  setCanResend,
  code,
  setCode
}) {

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCanResend(true);
    }
    return () => clearTimeout(timer);
  }, [counter]);

  if (!isOpen) {
    return null;
  }

  const onChange = (element, index) => {
    if (isNaN(element.value)) return false;
    let newCode = [...code];
    newCode[index] = element.value;
    setCode(newCode);
    if (element.nextSibling) element.nextSibling.focus();
  };
  
  return (
    <section className="validationModal_overlay" >
      <div className="validationModal">
          <div className="smsVerification_form">
            <div onClick={onClose}><RiCloseFill /></div>
            <div className="header">
              <h1 className="title">Verify Code</h1>
              <p className="subtitle">
                Please enter the verification code sent to your phone.
              </p>
            </div>
            <form className="form_wrap" onSubmit={onVerify}>
              <div className="code_group">
                {code.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="code_input_field"
                    value={value}
                    onChange={(e) => onChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                  />
                ))}
              </div>
              <button id="verify_btn" type="submit" disabled={isLoading}>
                {isLoading ? <div className="spinner"></div> : "Verify"}
              </button>
            </form>
            <div className="resend_code_section">
              {canResend ? (
                <button onClick={onResend} className="resend_code_btn">
                  Resend Code
                </button>
              ) : (
                <p className="text">Resend Code in {counter} seconds</p>
              )}
            </div>
            {error && <p className="error_msg">{error}</p>}
            {successMessage && <p className="success_msg">{successMessage}</p>}
          </div>
        </div>
    </section>
  );
}
